import ArtistPersonalInfos			from '../../public/assets/images/artiste/infospersos.jpg';
import ArtistPhoneNumber 				from '../../public/assets/images/artiste/phone-number.jpg';
import ArtistPhoneCode 					from '../../public/assets/images/artiste/phone-code.jpg';
import ArtistActivity 					from '../../public/assets/images/artiste/activite.jpg';
import ClientPersonalInfos			from '../../public/assets/images/client/infospersos.jpg';
import ClientPhoneNumber 				from '../../public/assets/images/client/phone1.jpg';
import ClientPhoneCode 					from '../../public/assets/images/client/phone2.jpg';


const TASK_STEPS = {
	ARTIST: [
		{
			title: 'artist.artistIdentity.title',
			value: 'identity',
			link: 'ArtistEmboardingIdentity',
			text: 'artist.artistIdentity.description',
			hero: ArtistPersonalInfos,
		},
		{
			title: 'artist.phoneValidation.title',
			value: 'phone',
			link: 'ArtistEmboardingTelephone',
			text: {
				phoneNumber: 'artist.phoneValidation.descriptionPhone',
				phoneCode: 'artist.phoneValidation.descriptionCode',
			},
			hero: {
				phoneNumber: ArtistPhoneNumber,
				phoneCode: ArtistPhoneCode,
			},
		},
		{
			title: 'artist.activity.title',
			value: 'activity',
			link: 'ArtistEmboardingActivity',
			text: 'artist.activity.description',
			hero: ArtistActivity,
		},
	],
	CLIENT: [
		{
			title: 'artist.artistIdentity.title',
			value: 'identity',
			link: 'ClientEmboardingIdentity',
			text: 'artist.dashboard.client.emboarding.identity.description',
			hero: ClientPersonalInfos,
		},
		{
			title: 'artist.phoneValidation.title',
			value: 'phone',
			link: 'ClientEmboardingTelephone',
			text: {
				phoneNumber: 'artist.phoneValidation.descriptionPhone',
				phoneCode: 'artist.phoneValidation.descriptionCode',
			},
			hero: {
				phoneNumber: ClientPhoneNumber,
				phoneCode: ClientPhoneCode,
			},
		},
	],
};


export {
	TASK_STEPS,
};
