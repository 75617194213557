<template>
  <MainLayout
    isFullScreen
    class="main-layout--artist-emboarding">
    <figure class="artist-emboarding__hero-wrapper">
      <img
        :src="currentStepHero"
        alt="Artist"
        class="artist-emboarding__hero-image"
      />
      <div class="artist-emboarding__hero-overlay">
        <router-link :to="{ name: 'LandingPage' }">
          <Logo
            :size="'base'"
            :color="'white'">
          </Logo>
        </router-link>
        <Icon
          v-if="currentStepIndex > 0"
          @click="handleNavigateSteps(-1)"
          :variant="'arrow-left-outline'"
          :tag="'button'"
          :size="'xs'"
          :backdropColor="'white'"
          type="button">
        </Icon>
      </div>
    </figure>

    <section class="artist-emboarding__content">
      <header :class="[
        'artist-emboarding__header',
        { 'artist-emboarding__header--artist': isArtist },
        ]
      ">
        <div :class="['artist-emboarding__step-summary-wrapper', wrapperStyle]">
          <div
            v-for="(step, index) in tasks"
            :key="`step-${index}`"
            :class="['artist-emboarding__step-summary-step', stepStyle(index)]">
          </div>
        </div>
        <div class="artist-emboarding__titles">
          <template v-for="(step, index) in tasks">
            <Heading
              v-if="step === currentStep"
              :tag="'h1'"
              :level="'h3'"
              :key="`step-title-${index}`"
              class="artist-emboarding__title">
              {{ index + 1 }}. {{ $t(currentStep.title) }}
            </Heading>
            <Paragraph
              v-else
              :size="'sm'"
              :key="`step-title-${index}`"
              class="artist-emboarding__title">
              {{ index + 1 }}. {{ $t(step.title) }}
            </Paragraph>
          </template>
        </div>
        <Paragraph class="artist-emboarding__text">
          {{
            currentStep.value === 'phone'
              ? hasSentCode
                ? $t(currentStep.text.phoneCode)
                : $t(currentStep.text.phoneNumber)
              : $t(currentStep.text)
          }}
        </Paragraph>
      </header>

      <router-view
        @success="handleNavigateSteps(1)"
        @code-sent="hasSentCode = true"
        :isArtist="isArtist">
      </router-view>
    </section>
  </MainLayout>
</template>

<script>

import MainLayout         from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Heading            from '../../../components/atoms/Heading/a-Heading.vue';
import Icon               from '../../../components/atoms/Icon/a-Icon.vue';
import Logo               from '../../../components/atoms/Logo/a-Logo.vue';
import Paragraph          from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import { TASK_STEPS }     from '../../../constants/task-actions.js';


export default {
  name: 'p-ArtistEmboarding',
  components: {
    MainLayout,
    Heading,
    Logo,
    Icon,
    Paragraph,
  },
  props: {
    isArtist: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hasSentCode: false,
  }),
  computed: {
    tasks() {
      return this.isArtist ? TASK_STEPS.ARTIST : TASK_STEPS.CLIENT;
    },
    currentStepIndex() {
      return this.$route.meta.stepIndex;
    },
    currentStep() {
      return this.tasks[this.currentStepIndex];
    },
    wrapperStyle() {
      return {
        [`artist-emboarding__step-summary-wrapper--${100 * (this.currentStepIndex / (this.tasks.length - 1))}`]: true,
      }
    },
    currentStepHero() {
      if (this.currentStep?.value && this.currentStep.value !== 'phone') return this.currentStep.hero;

      return this.hasSentCode
        ? this.currentStep.hero.phoneCode
        : this.currentStep.hero.phoneNumber;
    },
  },
  methods: {
    handleNavigateSteps(navigateBy) {
      const nextStepIndex = this.currentStepIndex + navigateBy;
      const nextStep = this.tasks[nextStepIndex];

      if (!nextStep) return this.$router.push({ name: this.isArtist ? 'ArtistEmboardingWelcome' : 'ClientEmboardingWelcome' });

      this.$router.push({ name: nextStep.link });
    },
    stepStyle(index) {
      return {
        'artist-emboarding__step-summary-step--active': index === this.currentStepIndex,
        'artist-emboarding__step-summary-step--completed': index < this.currentStepIndex,
      };
    }
  }
}

</script>

<style lang="scss">

.artist-emboarding {
  &__hero-wrapper {
    position: relative;
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    display: flex;

    @media screen and ($desktop) {
      grid-row: 1 / -1;
      grid-column: 1 / 2;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 4px;
      width: 100%;
      background: var(--color-gradient-main);
    }
  }

  &__hero-overlay {
    position: absolute;
    top: 40px;
    left: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;

    @media screen and ($desktop) {
      top: 100px;
    }
  }

  &__hero-image {
    object-fit: cover;
    min-width: 100%;

    @media screen and ($desktop) {
      position: fixed;
      left: 0;
      right: 0;
      min-width: 50%;
      max-width: 50%;
      min-height: 100%;
      max-height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;
    max-height: 100%;
    padding: var(--space-lg);
    overflow-y: auto;
    background-color: var(--color-white);

    @media screen and ($desktop) {
      grid-column: 2 / 3;
      padding: var(--space-xxl) var(--space-xl);
      padding-bottom: var(--space-xl);
      padding-right: var(--space-xxl);
    }
  }

  &__header {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 50px auto;
    grid-gap: var(--space-lg);
    margin-bottom: var(--space-md);

    &--artist {
      grid-template-rows: 80px auto;
    }
  }

  &__step-summary-wrapper {
    position: relative;
    height: 100%;
    width: 2px;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-grey-light);

    $steps: (
      0: 0%,
      50: 50%,
      100: 100%,
    );

    @each $step-key, $step-value in $steps {
      &--#{$step-key} {
        &:after {
          content: '';
          position: absolute;
          height: $step-value;
          width: 2px;
          background-color: var(--color-grey-semi);
        }
      }
    }

    @media screen and ($desktop) {
      max-width: 600px;
    }
  }

  &__step-summary-step {
    z-index: 1;
    height: 14px;
    width: 14px;
    border-radius: var(--rounded-circle);
    background-color: var(--color-grey-light);
    border: 2px solid var(--color-white);

    &--active {
      background-color: var(--color-black);
    }

    &--completed {
      background-color: var(--color-grey-neutral);
    }
  }

  &__titles {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    line-height: var(--lineheight-xs);
  }

  &__text {
    grid-column: 1 / -1;
    grid-row: 2 / 3;
  }
}

</style>
